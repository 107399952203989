import {shuffle} from "../utils/sorting";
import React from "react";
import {AbstractQuestion} from "../questions/Questions";

export class Questionnaire {
    private _list: AbstractQuestion[] = [];

    get questionMaxLength(): number {
        let maxLength = 0;
        for (const question of this._list) {
            maxLength = Math.max(question.getQuestionLength(), maxLength);
        }
        return maxLength;
    }

    get responseMaxLength(): number {
        let maxLength = 0;
        for (const question of this._list) {
            maxLength = Math.max(question.getResponseLength(), maxLength);
        }
        return maxLength;
    }

    get list(): AbstractQuestion[] {
        return this._list;
    }

    shuffleList() {
        this._list = shuffle(this._list)
    }

    get uniqueKey(): number {
        return Math.random() * Number.MAX_SAFE_INTEGER
    }
}
