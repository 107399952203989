import React, {useState} from 'react';
import {Form, Jumbotron} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {isNumber, randomIntFromInterval} from "../utils/number";
import {Questionnaire} from "../questionnaire/Questionnaire";
import {QuestionnaireTable} from "../components/QuestionnaireTable";
import {AdditionQuestion} from "../questions/Questions";

const AdditionPage = () => {
    const [minimumNumber, setMinimumNumber] = useState<string>("0")
    const [maximumNumber, setMaximumNumber] = useState<string>("30")
    const [numberOfQuestions, setNumberOfQuestions] = useState<number>(10)

    let minimumNumberInt = parseInt(minimumNumber);
    let maximumNumberInt = parseInt(maximumNumber);
    let questionnaire = new Questionnaire();

    for (let i = 1; i <= numberOfQuestions; i++) {
        let random1 = randomIntFromInterval(minimumNumberInt, maximumNumberInt)
        let random2 = randomIntFromInterval(minimumNumberInt, maximumNumberInt)
        questionnaire.list.push(new AdditionQuestion(random1, random2));
    }

    return <div>
        <Jumbotron>
            <h1>Addition<FontAwesomeIcon icon={faPlus} style={{marginLeft: '10px', marginRight: '10px'}}/></h1>

            <div>
                <Form inline>
                    <Form.Group>
                        <Form.Label>Välj tal: från</Form.Label>
                        <Form.Control as="input"
                                      value={minimumNumber}
                                      style={{width: '80px', marginLeft: '5px'}}
                                      pattern="\d*"
                                      onChange={event => setMinimumNumber(event.target.value)}/>
                        <Form.Label style={{marginLeft: '5px'}}>till</Form.Label>
                        <Form.Control as="input"
                                      value={String(maximumNumber)}
                                      style={{width: '80px', marginLeft: '5px'}}
                                      pattern="\d*"
                                      onChange={event => setMaximumNumber(event.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{marginLeft: '5px'}}>Antal</Form.Label>
                        <Form.Control as="select"
                                      value={String(numberOfQuestions)}
                                      style={{marginLeft: '5px'}}
                                      onChange={event => setNumberOfQuestions(parseInt(event.target.value))}>
                            <option key={10} value={10}>{10}</option>
                            <option key={20} value={20}>{20}</option>
                            <option key={30} value={30}>{30}</option>
                            <option key={40} value={40}>{40}</option>
                            <option key={50} value={50}>{50}</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </div>
        </Jumbotron>

        {isNumber(minimumNumber) && isNumber(maximumNumber) && numberOfQuestions && (
            <div>
                <QuestionnaireTable questionnaire={questionnaire}/>
            </div>
        )}
    </div>
}

export default AdditionPage;