import {Form} from "react-bootstrap";
import React from "react";

type NumberInputProps = {
    disabled?: boolean;
    onBlur: any;
    maxLength: number;
}

export const NumberInput = ({
                                disabled = false,
                                onBlur,
                                maxLength
                            }: NumberInputProps) => {
    let width = (maxLength+4) + 'ch'; // 4 is a magic number...
    return (
        <Form.Control
            className="mathInputResult"
            id="inlineFormInputName2"
            size={'lg'}
            style={{width: width}}
            placeholder=""
            type={"text"}
            pattern="\d*"
            disabled={disabled}
            onBlur={onBlur}
        />
    )
}