import React from 'react';
import './Footer.css'
import {envConfig} from "../App";
import {Navbar} from "react-bootstrap";
import Moment from "react-moment";

const Footer = () => {
    const frontendVersion = envConfig.BUILD_NUMBER;
    const frontendEnv = envConfig.ENVIRONMENT;
    const frontendBuildDateTime = envConfig.BUILD_DATE_TIME;

    return (
      <Navbar id="footer" className={ "footer" }>
        <Navbar.Text>
          ©Häggkvist 2020<br/>
          <b>Version</b>: { frontendVersion } ({ frontendEnv }) <Moment locale="sv" fromNow>{ frontendBuildDateTime }</Moment><br/>
        </Navbar.Text>
      </Navbar>
    );
}

export default Footer;