import React from 'react';
import './App.css';
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import MultiplicationPage from "./pages/MultiplicationPage";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AdditionPage from "./pages/AdditionPage";
import SubtractionPage from "./pages/SubtractionPage";
import MixPage from "./pages/MixPage";


// const ENV_CONFIG = (window as any).ENV_CONFIG;

type EnvConfig = {
    BUILD_NUMBER: string;
    ENVIRONMENT: string;
    BUILD_DATE_TIME: string;
    BACKEND_HOST: string;
};

export const envConfig = (window as any).ENV as EnvConfig;

function App() {
    return (
        <Router>
            <div className="App">
                <Header/>
                <Switch>
                    <Route exact path="/">
                        <HomePage/>
                    </Route>
                    <Route path="/multiplication">
                        <MultiplicationPage/>
                    </Route>
                    <Route path="/addition">
                        <AdditionPage/>
                    </Route>
                    <Route path="/subtraction">
                        <SubtractionPage/>
                    </Route>
                    <Route path="/mix">
                        <MixPage/>
                    </Route>
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
