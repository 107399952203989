import React, {ChangeEvent, useState} from 'react';
import {Form, Jumbotron} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Questionnaire} from "../questionnaire/Questionnaire";
import {QuestionnaireTable} from "../components/QuestionnaireTable";
import {MultiplicationQuestion} from "../questions/Questions";


const MultiplicationPage = () => {
    const [multiplicationTable, setMultiplicationTable] = useState<number>(0)
    const [numberOfQuestions] = useState<number>(12)

    function onMultiplicationTableChanged(event: ChangeEvent<HTMLSelectElement>) {
        setMultiplicationTable(parseInt(event.target.value))
    }

    let questionnaire = new Questionnaire()
    for (let i = 1; i <= numberOfQuestions; i++) {
        questionnaire.list.push(new MultiplicationQuestion(i, multiplicationTable));
    }
    questionnaire.shuffleList()

    return <div>
        <Jumbotron>
            <h1>Multiplikation<FontAwesomeIcon icon={faTimes} style={{marginLeft: '10px', marginRight: '10px'}}/></h1>

            <div>
                <Form inline>
                    <Form.Group>
                        <Form.Label>Välj tabell</Form.Label>
                        <Form.Control as="select"
                                      value={String(multiplicationTable)}
                                      onChange={onMultiplicationTableChanged}>
                            <option disabled value="0" key={0}>Välj</option>
                            {[...Array(20)].map((x, i) => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </div>
        </Jumbotron>


        {multiplicationTable > 0 && (
            <div>
                <QuestionnaireTable questionnaire={questionnaire} />
            </div>
        )}
    </div>
}

export default MultiplicationPage;