import React, {FocusEvent} from "react";
import {NumberReadonly} from "../components/form/NumberReadonly";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {getNumberLength} from "../utils/number";

/****************************************************************************************
 *                              AdditionQuestion
 ***************************************************************************************/
// TODO: we could have "TwoNumberAbstractQuestions" if we want to DRY it more
export abstract class AbstractQuestion {
    abstract renderQuestion(questionMaxLength: number): any;

    abstract isCorrect(event: FocusEvent<HTMLInputElement>): boolean;
    abstract getQuestionLength(): number;
    abstract getResponseLength(): number;
}

/****************************************************************************************
 *                              MultiplicationQuestion
 ***************************************************************************************/
export class MultiplicationQuestion extends AbstractQuestion {
    private readonly number1: number;
    private readonly number2: number;

    constructor(number1: number, number2: number) {
        super();
        this.number1 = number1;
        this.number2 = number2;
    }

    isCorrect(event: FocusEvent<HTMLInputElement>): boolean {
        let answer = parseInt(event.target.value);
        return (this.calculate(this.number1, this.number2) === answer)
    }

    calculate(x: number, y: number): number {
        return x * y;
    }

    renderQuestion(questionMaxLength: number): any {
        return (
            <>
                <NumberReadonly number={this.number1} maxLength={questionMaxLength}/>
                <FontAwesomeIcon icon={faTimes} style={{marginLeft: '10px', marginRight: '10px'}}/>
                <NumberReadonly number={this.number2} maxLength={questionMaxLength}/>
            </>
        )
    }

    getQuestionLength(): number {
        return getNumberLength(Math.max(this.number1, this.number2))
    }

    getResponseLength(): number {
        return getNumberLength(this.calculate(this.number1, this.number2))
    }
}

/****************************************************************************************
 *                              AdditionQuestion
 ***************************************************************************************/
export class AdditionQuestion extends AbstractQuestion {
    private readonly number1: number;
    private readonly number2: number;

    public constructor(number1: number, number2: number) {
        super();
        this.number1 = number1;
        this.number2 = number2;
    }

    isCorrect(event: FocusEvent<HTMLInputElement>): boolean {
        let answer = parseInt(event.target.value);
        return (this.calculate(this.number1, this.number2) === answer)
    }

    calculate(x: number, y: number): number {
        return x + y;
    }

    renderQuestion(questionMaxLength: number): any {
        return (
            <>
                <NumberReadonly number={this.number1} maxLength={questionMaxLength}/>
                <FontAwesomeIcon icon={faPlus} style={{marginLeft: '10px', marginRight: '10px'}}/>
                <NumberReadonly number={this.number2} maxLength={questionMaxLength}/>
            </>
        )
    }

    getQuestionLength(): number {
        return getNumberLength(Math.max(this.number1, this.number2))
    }

    getResponseLength(): number {
        return getNumberLength(this.calculate(this.number1, this.number2))
    }
}

/****************************************************************************************
 *                              SubtractionQuestion
 ***************************************************************************************/
export class SubtractionQuestion extends AbstractQuestion {
    private readonly number1: number;
    private readonly number2: number;

    /**
     * This ensures that the biggest number is to the left if negative is not allowed
     */
    public constructor(number1: number, number2: number, allowNegative: boolean) {
        super();
        if (!allowNegative && number2 > number1) {
            this.number1 = number2;
            this.number2 = number1;
        } else {
            this.number1 = number1;
            this.number2 = number2;
        }
    }

    isCorrect(event: FocusEvent<HTMLInputElement>): boolean {
        let answer = parseInt(event.target.value);
        return (this.calculate(this.number1, this.number2) === answer)
    }

    calculate(x: number, y: number): number {
        return x - y;
    }

    renderQuestion(questionMaxLength: number): any {
        return (
            <>
                <NumberReadonly number={this.number1} maxLength={questionMaxLength}/>
                <FontAwesomeIcon icon={faMinus} style={{marginLeft: '10px', marginRight: '10px'}}/>
                <NumberReadonly number={this.number2} maxLength={questionMaxLength}/>
            </>
        )
    }

    getQuestionLength(): number {
        return getNumberLength(Math.max(this.number1, this.number2))
    }

    getResponseLength(): number {
        return getNumberLength(this.calculate(this.number1, this.number2))
    }
}