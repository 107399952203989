import React from 'react';
import './Footer.css'
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

const Header = () => {

    return (
        <Navbar bg="light" expand="sm">
            <Navbar.Brand to="/" as={ Link }>Häggkvist matte</Navbar.Brand>
            {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
            {/*<Navbar.Collapse id="basic-navbar-nav">*/}
            {/*    <Nav className="mr-auto">*/}
            {/*        <Nav.Link to="/" as={ Link }>Home</Nav.Link>*/}
            {/*        <Nav.Link to="/multiplication" as={ Link }>Multiplikation</Nav.Link>*/}
            {/*    </Nav>*/}
            {/*</Navbar.Collapse>*/}
        </Navbar>
    );
}

export default Header;