import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";

type QuestionResultProps = {
    correct: boolean | null
}

export const QuestionResult = ({correct}: QuestionResultProps) => {
    let icon;
    // fa-fw -> https://fontawesome.com/how-to-use/on-the-web/styling/fixed-width-icons
    if (correct === true) {
        icon = <FontAwesomeIcon icon={faCheck} className={"fa-fw"} style={{color: 'green'}}/>
    } else if (correct === false) {
        icon = <FontAwesomeIcon icon={faExclamation} className={"fa-fw"} style={{color: 'red'}}/>
    } else {
        icon = <FontAwesomeIcon icon={faExclamation} className={"fa-fw"} style={{color: 'white'}}/> // just to have the exact same with always...
    }

    return <div style={{display: 'inline', marginLeft: '10px'}}>{icon}</div>
}