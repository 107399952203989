import React, {FocusEvent, useState} from 'react';
import {Questionnaire} from "../questionnaire/Questionnaire";
import {Col, Container, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEquals} from "@fortawesome/free-solid-svg-icons";
import {NumberInput} from "./form/NumberInput";
import {QuestionResult} from "./QuestionResult";
import {AbstractQuestion} from "../questions/Questions";

type QuestionnaireTableProps = {
    questionnaire: Questionnaire;
}

export const QuestionnaireTable = ({questionnaire}: QuestionnaireTableProps) => {
    let list = questionnaire.list

    console.log("list", list)

    return (
        <Form inline key={questionnaire.uniqueKey}>
            <Container fluid>
                {list.map((x: any, i: number) => {
                        return <QuestionnaireQuestion questionnaire={questionnaire} question={x} key={i}/>;
                    }
                )}
            </Container>
        </Form>
    );
}

type QuestionnaireQuestionProps = {
    questionnaire: Questionnaire;
    question: AbstractQuestion;
}

export const QuestionnaireQuestion = ({questionnaire, question}: QuestionnaireQuestionProps) => {
    const [answeredCorrectly, setAnsweredCorrectly] = useState<boolean | null>(null)

    function onMultiplicationTableBlur(question: AbstractQuestion, event: FocusEvent<HTMLInputElement>) {
        if (event.target.value === "") {
            return
        }
        setAnsweredCorrectly(question.isCorrect(event));
    }

    console.log("questionnaire.responseMaxLength", questionnaire.responseMaxLength)
    let renderedQuestion = question.renderQuestion(questionnaire.questionMaxLength)

    return (
        <Row style={{marginTop: '5px'}}>

            <Col className={"align-center align-items-center justify-content-md-center"} lg={12} md={12} sm={12} xl={12} xs={12}>
                {renderedQuestion}

                <FontAwesomeIcon icon={faEquals} style={{marginLeft: '10px', marginRight: '10px'}}/>

                <NumberInput
                    maxLength={questionnaire.responseMaxLength}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => onMultiplicationTableBlur(question, e)}/>

                <QuestionResult correct={answeredCorrectly}/>
            </Col>
        </Row>
    )
}

