import React from 'react';
import {Button, Jumbotron, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinus, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons'

const HomePage = () => {

    return <div>
        <Jumbotron>
            <h1>Välkommen till mattesidan!</h1>
            {/*<p>*/}
            {/*    This is a simple hero unit, a simple jumbotron-style component for calling*/}
            {/*    extra attention to featured content or information.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    <Button variant="primary">Learn more</Button>*/}
            {/*</p>*/}
        </Jumbotron>

        <Nav.Link to="/multiplication" as={Link}>
            <Button variant="primary" size="lg">
                Multiplikation<FontAwesomeIcon icon={faTimes} style={{marginLeft: '10px', marginRight: '10px'}}/>
            </Button>
        </Nav.Link>

        <Nav.Link to="/addition" as={Link}>
            <Button variant="primary" size="lg">
                Addition<FontAwesomeIcon icon={faPlus} style={{marginLeft: '10px', marginRight: '10px'}}/>
            </Button>
        </Nav.Link>

        <Nav.Link to="/subtraction" as={Link}>
            <Button variant="primary" size="lg">
                Subtraktion<FontAwesomeIcon icon={faMinus} style={{marginLeft: '10px', marginRight: '10px'}}/>
            </Button>
        </Nav.Link>

        <Nav.Link to="/mix" as={Link}>
            <Button variant="primary" size="lg">
                <FontAwesomeIcon icon={faPlus} style={{marginLeft: '10px', marginRight: '10px'}}/>
                &
                <FontAwesomeIcon icon={faMinus} style={{marginLeft: '10px', marginRight: '10px'}}/>
            </Button>
        </Nav.Link>
{/*
        <hr/>

        <Nav.Link to="/multiplication" as={Link}>
            <Button variant="primary">
                <Form inline>
                    <Form.Control
                        className="mathInput xs-1"
                        size={'lg'}
                        id="inlineFormInput"
                        placeholder="6"
                        disabled={true}
                    />
                    <FontAwesomeIcon icon={faTimes} style={{marginLeft: '10px', marginRight: '10px'}}/>
                    <Form.Control
                        className="mathInput xs-1"
                        id="inlineFormInputName2"
                        size={'lg'}
                        placeholder="4"
                        disabled={true}
                    />
                    <FontAwesomeIcon icon={faEquals} style={{marginLeft: '10px', marginRight: '10px'}}/>
                    <Form.Control
                        className="mathInput xs-1"
                        id="inlineFormInputName2"
                        size={'lg'}
                        placeholder="?"
                        disabled={true}
                    />
                </Form>
            </Button>
        </Nav.Link>
*/}

        {/*
        <hr/>

        <Form inline>
            <Form.Control
                className="mathInput xs-1"
                size={'lg'}
                id="inlineFormInput"
                placeholder="6"
            />
            {' '}
            {'+'}
            {' '}
            <Form.Control
                className="mathInput xs-1"
                id="inlineFormInputName2"
                size={'lg'}
                placeholder="4"
            />
        </Form>*/}


    </div>
}

export default HomePage;