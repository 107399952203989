import {Form} from "react-bootstrap";
import React from "react";

type NumberReadonlyProps = {
    number: number;
    maxLength: number;
}

export const NumberReadonly = ({
                                   number,
                                   maxLength
                               }: NumberReadonlyProps) => {

    let width = (maxLength+4) + 'ch'; // 4 is a magic number...
    return (
        <Form.Control
            className="mathInput"
            size={'lg'}
            style={{width: width}}
            placeholder={String(number)}
            disabled={true}
        />
    )
}